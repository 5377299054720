import headerPic from "../../img/RetailPic/RetilePic1.png";
import headerPic2 from "../../img/RetailPic/RetilePic2.png";
import Button from "../Button/Button";
import bacground from "../../img/RetailPic/Vector.svg";
import Phone from "../../img/RetailPic/Phone.png";
import Phone2 from "../../img/RetailPic/Phone2.png";
import icon from "../../img/RetailPic/Icon.svg";
import systemIcon1 from "../../img/RetailPic/dollar.svg";
import systemIcon2 from "../../img/RetailPic/smartphone.svg";
import systemIcon3 from "../../img/RetailPic/usercircle.svg";
import systemIcon4 from "../../img/RetailPic/checkcircle.svg";
import "./Retail.css"

function Retail({lang, setIsTrue}) {

  const navigateToExternalUrl = () => {
      //go to pcassa.am page while clicking on BlueBlock button
    window.open('https://pcassa.am/', '_blank');
  };
  
    return (
     <div className="PublicFood_wrapper">
      {/* header */}
      <div className="PublicFood_header">
              <div className="PublicFood_header_text">
                <h1>
                  {lang.Retail_header_title} <span>Pcassa</span> {lang.ov}
                </h1>
              </div>
              <div className="PublicFood_header_picture">
                <img id="PublicFood_pic_for_laptop" alt="pic" src={headerPic} />
                <img id="PublicFood_pic_for_mobile" alt="pic" src={headerPic2} />
                <div id="PublicFood_button_component">
                  <Button lang={lang} setIsTrue={setIsTrue}/>
                </div>
              </div>
      </div>
      {/* block2 */}
      <div className="MainPage_support_block" id="Retail_support_block">
        <div className="MainPage_support_text"id="Retail_support_text">
          <h2>{lang.Retail_block2_title}</h2>
          <p>
            <img alt="icon" src={icon} />
            {lang.Retail_block2_text1}
          </p>
          <p>
            <img alt="icon" src={icon} />
            {lang.Retail_block2_text2}
          </p>
          <p>
            <img alt="icon" src={icon} />
            {lang.Retail_block2_text3}
          </p>
          <p>
            <img alt="icon" src={icon} />
            {lang.Retail_block2_text4}
          </p>
        </div>
        <div className="MainPage_phone1" id="Retail_phone1">
          <img alt="MainPage_phone" src={Phone} />
        </div>
        <div className="MainPage_phone2">
          <img id="Retail_phone2" alt="MainPage_phone" src={Phone2}/>
        </div>
        <img id="MainPage_BCGimg" alt="MainPage_bcg" src={bacground} />
      </div>
      {/* block3 */}
      <div className="PublicFood_system_block">
        <h2 className="PublicFood_system_title">{lang.Retail_block3_title}</h2>
        <p className="PublicFood_system_paragraf">{lang.Retail_block3_subtitle}</p>
        <div className="PublicFood_flex_block Retail_flex_block1">
            <div className="PublicFood_system_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon1}/></div>
                <p className="">{lang.Retail_block3_1}</p>
            </div>
            <div className="PublicFood_system_item ">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon2}/></div>
                <p className="">{lang.Retail_block3_2}</p>
            </div>
            <div className="PublicFood_system_item ">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon3}/></div>
                <p className="">{lang.Retail_block3_3}</p>
            </div>
            <div className="PublicFood_system_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon4}/></div>
                <p className="">{lang.Retail_block3_4}</p>
            </div>
        </div>
      </div>
       {/* Blue_block */}
       <div className="PublicFood_Blue_block">
            <p>{lang.blue_block_text3}</p>
            <div className="PublicFood_button_wrap">
                <button className="Blue_block_button" onClick={()=>{
      // setIsTrue(true)
      navigateToExternalUrl()
    }}>
                {lang.Learn_more}
                </button>
            </div>
      </div>
     </div>
     
    ) 
  }
  
  export default Retail