
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Layout from './Layout';
import languages from "./languages.json"
import { useEffect, useState } from 'react';
function App() {
  const [ lang, setLang ] = useState(localStorage.getItem("choosenLanguage")? languages[localStorage.getItem("choosenLanguage")]:languages.RU)

  let ScrollToTop =() => {
    const { pathname } = useLocation()
    useEffect(() => {
      window.scrollTo(0,0)
    },[pathname])

    return null
  }

ScrollToTop()
  return (
    <div className="App">

      <Routes>
        <Route path="/*" element={<Layout lang={lang} setLang={setLang}/>}/> 
      </Routes>
    </div>
  );
}

export default App;
