import "./Footer.css";
import logo from "../../img/ekayLogo.svg";
import FB from "../../img/VectorFBblue.svg";
import FBWhite from "../../img/VectorFBwhite.svg"
import Insta from "../../img/VectorinstaBlue.svg";
import Insta2 from "../../img/VectorinstaWhite.svg";
import { useNavigate } from "react-router-dom";

function Footer({ lang }) {
  const navigate =useNavigate()
  return (
    <footer>
      <div className="footer_wrapper">
        <div className="footer_logo">
          <img alt="logo" src={logo} />
          <p className="footer_p_logo">{lang.Footer_text1}</p>
        </div>
        <div className="footer_menu_flex">
          <div className="footer_menu_solutions">
            <h4>{lang.Solutions}</h4>
            <p className="footer_p" onClick={() => {
                        navigate("MobileApp");   
                      }}>{lang.Restaurant_app}</p>
            <p className="footer_p"  onClick={() => {
                        navigate("PublicFood");
                      }}>{lang.Conceptual_restaurant}</p>
            <p className="footer_p"  onClick={() => {
                        navigate("Retail");
                      }}>{lang.Retail_outlet}</p>
            <p className="footer_p" onClick={() => {
                        navigate("EntertainmentCenters");
                      }}>{lang.Entertainment_Center}</p>
            <p className="footer_p" onClick={() => {
                        navigate("Fitness");
                      }}>{lang.Sports_facility}</p>
            <p className="footer_p" onClick={() => {
                        navigate("Hotels");
                      }}>{lang.Hotel}</p>
            <p className="footer_p" onClick={() => {
                        navigate("Cinema");
                      }}>{lang.Cinema}</p>
            <p className="footer_p"  onClick={() => {
                        navigate("FastFood");
                      }}>{lang.FastFood}</p>
          </div>
          <div className="footer_menu_roducts">
            <h4>{lang.Products}</h4>
            <p className="footer_p"  onClick={() => {
                        navigate("PublicFood");
                      }}>r_keeper</p>
            <p className="footer_p" onClick={() => {
                        navigate("Hotels");
                      }}>Shelter</p>
            <p className="footer_p" onClick={() => {
                        navigate("Retail");
                      }}>Pcassa</p>
            <p className="footer_p" onClick={() => {
                        navigate("MobileApp");
                      }}>RestA</p>
            <p className="footer_p" onClick={() => {
                        navigate("Cinema");
                      }}>{lang.Software_Premiere}</p>
            <p className="footer_p" onClick={() => {
                        navigate("EntertainmentCenters");
                      }}>Game-Keeper</p>
            <p className="footer_p" onClick={() => {
                        navigate("Fitness");
                      }}>{lang.Software_Subscription}</p>
          </div>
          <div className="Equipment_and_Contacts">
            <h4 onClick={() => {
                        navigate("Hardware");  
                      }}>{lang.Equipment}</h4>
            <h4>{lang.Contacts}</h4>
            <div className="footer_contacts">
              <p className="footer_contacts_phone_number">+374 99 545445</p>
              <p className="footer_contacts_phone_number">+374 60 545445</p>
              <p className="footer_contacts_email"><a href="mailto:name@mail.ru?">info@ekey.am</a></p>
            </div>
            <div className="footer_social_media">
              <button>
              <a className="button_flex_a" href="https://www.facebook.com/EkeyTech" target="_blank" rel=" noreferrer">
                <img alt="fb" src={FB} className="fb1"/>
                <img alt="fb" src={FBWhite} className="fb2" />
             </a>
              </button>
              <button >
              <a className="button_flex_a" href="https://www.instagram.com/ekeytec" target="_blank" rel="noreferrer">
                <img alt="insta" src={Insta} className="insta1"/>
                <img alt="insta" src={Insta2} className="insta2"/>
               </a>
              </button>
            </div>
            <p className="legal_info">{lang.Legal_information}</p>
          </div>
        </div>
        <div className="footer_hidden_block">
          <h4>{lang.Contacts}</h4>
          <div className="footer_contacts">
              <p className="footer_contacts_phone_number">+374 99 545445</p>
              <p className="footer_contacts_phone_number">+374 60 545445</p>
              <p className="footer_contacts_email"><a href="mailto:name@mail.ru">info@ekey.am</a></p>
            </div>
            <div className="footer_social_media">
            <button >
            <a className="button_flex_a" href="https://www.facebook.com/EkeyTech" target="_blank" rel="noreferrer">
              <img alt="fb" src={FB} className="fb1"/>
              <img alt="fb" src={FBWhite} className="fb2" />
            </a>
            </button>
            <button >
            <a className="button_flex_a" href="https://www.instagram.com/ekeytec" target="_blank" rel="noreferrer">
              <img alt="insta" src={Insta} className="insta1"/>
              <img alt="insta" src={Insta2} className="insta2"/>
            </a>
            </button>
          </div>
          <p className="legal_info">{lang.Legal_information}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
