import "./Popup.css";
import bacground from "../../img/Vector.svg"
import checkicon from "../../img/check.png"
function Accepting({lang}) {
  return (
    <div className="popup">
        <div className="popup_inner popup_inner2" >
        <img alt="bcg" src={bacground} id="bcg_Img"/> 
        <div className="form_inner form_inner2">
            <h3>{lang.Accept}</h3>
            <p>{lang.Accept_subtitle}</p>
          
            <div className="check_icon">
                <img alt="" src={checkicon}/>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Accepting
