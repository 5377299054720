import { useNavigate } from "react-router-dom";
import  "./Header.css";
import Logo from "../../img/ekayLogo.svg";
import iconArrowDown from "../../img/Iconarrow.svg";
import languages from "../../languages.json";
import BurgerButton from "../../img/Burger.svg";
import CloseButton from "../../img/CloseburgerButton.svg";
import FB from "../../img/VectorFBblue.svg";
// import FBactive from "../../img/VectorFBwhite.svg";
import Insta from "../../img/VectorinstaBlue.svg";
// import InstaActive from "../../img/VectorinstaWhite.svg";
import { useEffect, useRef, useState } from "react";
import { useClickOutside } from 'use-events';
import FBWhite from "../../img/VectorFBwhite.svg";
import Insta2 from "../../img/VectorinstaWhite.svg";

function Header({ lang, setLang}) {
  const navigate = useNavigate();
  const RefBurger = useRef(null);
  const RefNavbar = useRef(null);
  const RefWindow1 = useRef(null);
  const RefWindow2 = useRef(null);
  const RefLang = useRef(null);
  const [counter1, setCounter1] = useState(false);
  const [counter2, setCounter2] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [forceRender, setForceRender] = useState(false);
  

  useClickOutside([RefWindow1, RefWindow2,RefLang], event => {
    RefWindow2.current.classList.remove("active_li");
    setCounter2(false);
    RefWindow1.current.classList.remove("active_li");
    setCounter1(false);
     RefLang.current.classList.remove("active");
  });
  // scroll, header bacground color changing
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(()=>{
    if(scrollPosition > 0){
      RefBurger.current.classList.add("Header_backgraound");
    }else{
      RefBurger.current.classList.remove("Header_backgraound");
    }
  },[scrollPosition])

 
  // ////////////
  const scrollToFooter=()=>{
    window.scrollTo({
      top: document.documentElement.scrollHeight,
     behavior: 'smooth',
  });
  RefBurger.current.classList.remove("Header_active");
 }

  const openBurger = () => {
    RefBurger.current.classList.add("Header_active");
  };
  const closeBurger = () => {
    RefBurger.current.classList.remove("Header_active");
    RefBurger.current.classList.add("Header_close");
    RefWindow2.current.classList.remove("active_li");
    setCounter2(false);
    RefWindow1.current.classList.remove("active_li");
    setCounter1(false);
  };
  const Window1Onclick = () => {
    RefWindow1.current.classList.toggle("active_li");
    setCounter1(!counter1);
    RefWindow2.current.classList.remove("active_li");
    setCounter2(false);
  };
  const Window2Onclick = () => {
    RefWindow2.current.classList.toggle("active_li");
    setCounter2(!counter2);
    RefWindow1.current.classList.remove("active_li");
    setCounter1(false);
  };
  const toggleLanguagesDiv = () => {
     RefLang.current.classList.toggle("active");
  };
  const changeLanguage = (choosenLanguage) =>{

    localStorage.setItem("choosenLanguage",choosenLanguage)
    setLang(languages[choosenLanguage])

    setForceRender((prev) => !prev);
  }
  
  const AllLanguages = Object.keys(languages);

  
  return (
    <header 
      className='header_wrapper'
      ref={RefBurger}
      // style={backgroundStyle}
    >
      <div className='header_container'>
        <nav className='header_navigation'>
          <div className='header_logo'>
            <img alt=""
              src={Logo}
              onClick={() => {
                navigate("/");
                closeBurger();
              }}
            ></img>
          </div>
          <div className='header_navigation__container' ref={RefNavbar}>
            <div className='header_navigation_menu'>
              <ul className='menu__list'>
                <li
                  value={counter1}
                  onClick={Window1Onclick}
                  // onMouseOver={Window1Onclick}
                >
               
                  {lang.Solutions}
                  <img alt=""
                    className='iconArrowDown'
                    src={iconArrowDown}
                    style={{
                      transform: counter1 ? `rotate(180deg)` : `rotate(0deg)`,
                    }}
                  />
                </li>
                <div className='window1' ref={RefWindow1}>
                  <ul>
                    <li  onClick={() => {
                        navigate("MobileApp");
                        closeBurger();
                      }}>{lang.Restaurant_App}</li>
                    <li
                      onClick={() => {
                        navigate("PublicFood");
                        closeBurger();
                      }}
                    > {lang.Conceptual_Restaurant} </li>
                    <li onClick={() => {
                        navigate("Retail");
                        closeBurger();
                      }}>{lang.Retail_outlet}</li>
                    <li onClick={() => {
                        navigate("EntertainmentCenters");
                        closeBurger();
                      }}>{lang.Entertainment_Center}</li>
                    <li onClick={() => {
                        navigate("Fitness");
                        closeBurger();
                      }}>{lang.Sports_facility}</li>
                    <li   onClick={() => {
                        navigate("Hotels");
                        closeBurger();
                      }}>{lang.Hotel}</li>
                    <li   onClick={() => {
                        navigate("Cinema");
                        closeBurger();
                      }}>{lang.Cinema}</li>
                    <li
                      onClick={() => {
                        navigate("FastFood");
                        closeBurger();
                      }}> {lang.Fast_food}</li>
                  </ul>
                </div>
                <li
                  value={counter2}
                  onClick={Window2Onclick}
                  // onMouseOver={Window2Onclick}
                  ref={RefWindow2}
                >
                  {lang.Products}
                  <img alt=""
                    className='iconArrowDown'
                    src={iconArrowDown}
                    style={{
                      transform: counter2 ? `rotate(180deg)` : `rotate(0deg)`,
                    }}
                  />
                </li>
                <div className='window2' ref={RefWindow2}>
                  <ul>
                    <li onClick={() => {
                        navigate("PublicFood");
                        closeBurger();
                      }}>r_keeper</li>
                    <li onClick={() => {
                        navigate("Hotels");
                        closeBurger();
                      }}>Shelter</li>
                    <li onClick={() => {
                        navigate("Retail");
                        closeBurger();
                      }}>Pcassa</li>
                    <li onClick={() => {
                        navigate("MobileApp");
                        closeBurger();
                      }}>RestA</li>
                    <li onClick={() => {
                        navigate("Cinema");
                        closeBurger();
                      }}>{lang.Software_Premiere}</li>
                    <li onClick={() => {
                        navigate("EntertainmentCenters");
                        closeBurger();
                      }}>Game-Keeper</li>
                    <li onClick={() => {
                        navigate("Fitness");
                        closeBurger();
                      }}>{lang.Software_Subscription}</li>
                  </ul>
                </div>
                <li onClick={() => {
                        navigate("Hardware"); 
                        RefBurger.current.classList.remove("Header_active");
                      }}>{lang.Equipment}</li>
                <li onClick={scrollToFooter}>{lang.Contacts}</li>
              </ul>
            </div>
            <div className='header_navigation_right'>
            <div
                className='header_navigation_languages'
                 onClick={toggleLanguagesDiv}
              > {localStorage.getItem("choosenLanguage")? localStorage.getItem("choosenLanguage"):"RU"}
              
                {Object.keys(lang).find((key) => AllLanguages[key] === lang)}
                <img alt="" src={iconArrowDown} className="iconArrowDown"></img>
                <div className='AllLanguages' ref={RefLang}>
                  {
                    // find the language that exist and ingnor it than create a p tag with other languages//AllLanguages=[AM,RU,US]
                    AllLanguages.map((key) => {
                      const existingLang = Object.keys(AllLanguages).find(
                        (key) => AllLanguages[key] === lang
                      );
                      return key !== existingLang ? <p key={key} onClick={()=>{changeLanguage(key)}}>{key} </p> : ""
                     })
                    }
                 </div>
              </div>

            
              <div className='social_media'>
                <button>
                <a className="button_flex_a" href="https://www.facebook.com/EkeyTech" target="_blank" rel="noreferrer">
                  <img alt="fb" src={FB} className="fb1"/>
                  <img alt="fb" src={FBWhite} className="fb2" />
                </a>
                </button>
                <button>
                  <a className="button_flex_a" href="https://www.instagram.com/ekeytec" target="_blank" rel="noreferrer">
                      <img alt="insta" src={Insta} className="insta1"/>
                      <img alt="insta" src={Insta2} className="insta2"/>
                  </a>
                </button>
              </div>
              <div className='header_navigation_contacts'>
                <p className='contacts_phone_number'>+374 99 545445</p>
                <p className='contacts_email'><a href="mailto:info@ekey.am">info@ekey.am</a></p>
              </div>
            </div>
          </div>
          <div className='burgerButton'>
            <img alt=""
              className='burger'
              src={BurgerButton}
              onClick={openBurger}
            />
            <img alt=""
              className='CloseButton'
              src={CloseButton}
              onClick={closeBurger}
            />
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
