import Button from "../Button/Button"
import  "./Hotels.css"

import icon from "../../img/iconsFastFood/checkarrow.svg"
import headerPic2 from "../../img/Hotels/hotelsMainPhoto.png"
import headerPic1 from "../../img/Hotels/mainPhoto2.png" 
import systemIcon1 from "../../img/Hotels/Group 127.png"
import systemIcon2 from "../../img/Hotels/Group 128.png"
import systemIcon3 from "../../img/Hotels/Group 126.png"
import systemIcon4 from "../../img/Hotels/Group 124.png"
import systemIcon5 from "../../img/Hotels/Group 116.png"
import systemIcon6 from "../../img/Hotels/Group 125.png"
import systemIcon7 from "../../img/Hotels/Icon.png"
import Img1 from "../../img/Hotels/Rectangle 45.png"
import Img2 from "../../img/Hotels/Rectangle 45 (1).png"
import Img3 from "../../img/Hotels/Rectangle 45 (2).png"
import Img4 from "../../img/Hotels/Rectangle 45 (3).png"
import Img5 from "../../img/Hotels/Rectangle 45 (4).png"
import bacground from "../../img/Hotels/Vector.svg"
import desktop from "../../img/Hotels/decor.png"
import icon1 from "../../img/Hotels/Group136.svg"
import icon2 from "../../img/Hotels/Group137.svg"
import icon3 from "../../img/Hotels/Group135.svg"
import icon4 from "../../img/Hotels/Icon.svg"
import icon5 from "../../img/Hotels/Group133.svg"
import icon6 from "../../img/Hotels/Group134.svg"
import SlideLine from "../SlideLine/SlideLine"
function Hotels({lang, setIsTrue}) {
    return (
     <div className="PublicFood_wrapper">
      {/* header */}
      <div className="PublicFood_header">
          <div className="PublicFood_header_text">
            <h1>
              {lang.Hotels_title} <span>Shelter</span>{lang.ov}
            </h1>
          </div>
          <div className="PublicFood_header_picture">
            <img id="PublicFood_pic_for_laptop" alt="pic" src={headerPic1} />
            <img id="PublicFood_pic_for_mobile" alt="pic" src={headerPic2} />
            <div id="PublicFood_button_component">
              <Button lang={lang} setIsTrue={setIsTrue}/>
            </div>
          </div>
      </div>
       {/* System */}
       <div className="PublicFood_system_block">
        <h2 className="PublicFood_system_title">{lang.Hotels_title_2}</h2>
        <p className="PublicFood_system_paragraf">{lang.Hotels_block2_text1}</p>
        <div className="PublicFood_flex_block Hotels_flex_block1">
            <div className="PublicFood_system_item Hotels_system_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon1}/></div>
                <p className="Hotels_maxText">{lang.Hotels_block2_text2}</p>
            </div>
            <div className="PublicFood_system_item Hotels_system_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon2}/></div>
                <p className="Hotels_maxText">{lang.Hotels_block2_text3}</p>
            </div>
            <div className="PublicFood_system_item Hotels_system_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon3}/></div>
                <p className="Hotels_miniText">{lang.Hotels_block2_text4}</p>
            </div>
        </div>
        <div className=" PublicFood_flex_block Hotels_flex_block2">
            <div className="PublicFood_system_item Hotels_system_item ">
                <div className="PublicFood_systemIcon"><img  alt="icon" src={systemIcon4}/></div>
                <p className="Hotels_maxText">{lang.Hotels_block2_text5}</p>
            </div>
            <div className="PublicFood_system_item Hotels_system_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon5}/></div>
                <p className="Hotels_miniText">{lang.Hotels_block2_text6}</p>
            </div>
            <div className="PublicFood_system_item Hotels_system_item">
                <div className="PublicFood_systemIcon"><img  alt="icon" src={systemIcon6}/></div>
                <p className="Hotels_maxText">{lang.Hotels_block2_text7}</p>
            </div>
        </div>
        <div  className="PublicFood_flex_block Hotels_flex_block3">
            <div className="PublicFood_system_item Hotels_system_item">
                    <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon7}/></div>
                    <p className="Hotels_miniText">{lang.Hotels_block2_text8}</p>
            </div>
        </div>
      </div>
      {/* pic and text flex box */}
      <div className="PublicFood_flex_container">
        <h2>{lang.Hotels_block2_title}</h2>
      <div className="PublicFood_flex_box">
        <div className="PublicFood_flex_box__element">
            <div className="PublicFood_flex_text">
                <h4>{lang.Hotels_block2_subtitle1}</h4>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_1}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_2}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_3}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_4}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_5}
                </p>
            </div>
            <div className="PublicFood_flex_img">
                <img alt="" src={Img1}/>
            </div>
        </div>
        <div className="PublicFood_flex_box__element" id="PublicFood_box__element">
            <div className="PublicFood_flex_text">
                <h4>{lang.Hotels_block2_subtitle2}</h4>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_6}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_7}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_8}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_9}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_10}
                </p>
            </div>
            <div className="PublicFood_flex_img">
                <img alt="" src={Img2}/>
            </div>
        </div>
        <div className="PublicFood_flex_box__element">
            <div className="PublicFood_flex_text">
                <h4>{lang.Hotels_block2_subtitle3}</h4>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_11}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_12}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_13}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_14}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_15}
                </p>
            </div>
            <div className="PublicFood_flex_img">
                <img alt="" src={Img3}/>
            </div>
        </div>
        <div className="PublicFood_flex_box__element" id="PublicFood_box__element">
            <div className="PublicFood_flex_text">
                <h4>{lang.Hotels_block2_subtitle4}</h4>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_16}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_17}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_18}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_19}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_20}
                </p>
            </div>
            <div className="PublicFood_flex_img">
                <img alt="" src={Img4}/>
            </div>
        </div>
        <div className="PublicFood_flex_box__element">
            <div className="PublicFood_flex_text">
                <h4>{lang.Hotels_block2_subtitle5}</h4>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_21}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_22}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_23}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_24}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {lang.Hotels_block2_25}
                </p>
            </div>
            <div className="PublicFood_flex_img">
                <img alt="" src={Img5}/>
            </div>
        </div>
      </div>
      </div>
    {/* block 3 */}
        <div className="PublicFood_block">
            <div className="PublicFood_support_text">
            <h2>{lang.Hotels_block3_title}</h2>
                <div className="PublicFood_text">
                        <div className="Hotels_text_row" > 
                          <div className="Hotels_text_column" >
                            <h4>
                                r_keeper
                            </h4>
                            <h4>
                                {lang.Hotels_block3_2}
                            </h4>
                          </div>
                          <div className="Hotels_text_column" >
                           <p> 
                                {lang.Hotels_block3_1}
                            </p>
                            <p> 
                                {lang.Hotels_block3_4}
                            </p>
                           </div>
                           <div className="Hotels_text_column" >
                            <h4>
                                StoreHouse
                            </h4>
                            <h4>
                                Pooljet
                            </h4>
                           </div>
                           <div className="Hotels_text_column" > 
                            <p> 
                                {lang.Hotels_block3_3}
                            </p>
                            <p> 
                                {lang.Hotels_block3_5}
                            </p>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="PublicFood_iphone Hotels_desctopImg">
            <img id="iphone" alt="phone" src={desktop} />
            </div>
            <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
        </div>
    {/* block 4 */}
        <div className="PublicFood_flex_wrapp">
            <div className="PublicFood_flex_row">
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon1} />
                    </div>
                    <h3>{lang.Hotels_block4_1}</h3>
                    <p>{lang.Hotels_block4_2}</p>
                    </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon2} />
                    </div>
                    <h3>{lang.Hotels_block4_3}</h3>
                    <p>{lang.Hotels_block4_4}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon3} />
                    </div>
                    <h3>{lang.Hotels_block4_5}</h3>
                    <p>{lang.Hotels_block4_6}</p>
                </div>
            </div>
            <div className="PublicFood_flex_row">
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon4} />
                    </div>
                    <h3>{lang.Hotels_block4_7}</h3>
                    <p>{lang.Hotels_block4_8}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon5} />
                    </div>
                    <h3>{lang.Hotels_block4_9}</h3>
                    <p>{lang.Hotels_block4_10}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon6} />
                    </div>
                    <h3>{lang.Hotels_block4_11}</h3>
                    <p>{lang.Hotels_block4_12}</p>
                </div>
            </div>
        </div>
    {/* sliding-line  */}
    <SlideLine lang={lang}/>

    {/* Blue_block */}
        <div className="PublicFood_Blue_block">
          
            <p>{lang.blue_block_text_2}</p>
            <div className="PublicFood_button_wrap">
                <button className="Blue_block_button" onClick={()=>{
                                                        setIsTrue(true)
                                                        }}>
                {lang.Calculate_the_cost}
                </button>
            </div>
        </div>
    </div>
    ) 
  }
  
  export default Hotels