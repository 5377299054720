import Button from "../Button/Button";
import headerPic from "../../img/Fitnessimg/headerpic2.png";
import headerPic2 from "../../img/Fitnessimg/headerpic1.png"
import Img1 from "../../img/Fitnessimg/Rectangle.png";
import Img2 from "../../img/Fitnessimg/Rectangle1.png";
import Img3 from "../../img/Fitnessimg/Rectangle2.png";
import Img4 from "../../img/Fitnessimg/Rectangle3.png";
import Img5 from "../../img/Fitnessimg/img5.png";
import icon from "../../img/iconsFastFood/checkarrow.svg";
import desktop from "../../img/Fitnessimg/compPic.png";
import bacground from "../../img/Groupbcg.svg";
import SlideLine from "../SlideLine/SlideLine";

function Fitness({lang, setIsTrue}) {
    return (
      <div>
      {/* header */}
      <div className="PublicFood_header">
       <div className="PublicFood_header_text">
         <h1>
           {lang.Fitness_title_part1} <span> {lang.Fitness_title_part2} </span>
         </h1>
         <p>{lang.Fitness_subtitle}</p>
       </div>
       <div className="PublicFood_header_picture">
         <img id="PublicFood_pic_for_laptop" alt="pic" src={headerPic} />
         <img id="PublicFood_pic_for_mobile" alt="pic" src={headerPic2} />
         <div id="PublicFood_button_component">
           <Button lang={lang} setIsTrue={setIsTrue}/>
         </div>
       </div>
     </div>
      {/* pic and text flex box */}
      <div className="PublicFood_flex_container">
          <h2>{lang.Fitness_title2}</h2>
          <div className="PublicFood_flex_box">
          <div className="PublicFood_flex_box__element">
              <div className="PublicFood_flex_text">
                  <h4>{lang.Fitness_subtitle_2}</h4>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text1}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text2}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text3}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text4}
                  </p>
              </div>
              <div className="PublicFood_flex_img">
                  <img alt="" src={Img1}/>
              </div>
          </div>
          <div className="PublicFood_flex_box__element" id="PublicFood_box__element">
              <div className="PublicFood_flex_text">
                  <h4>{lang.Fitness_subtitle_3}</h4>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text5}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text6}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text7}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text8}
                  </p>
              </div>
              <div className="PublicFood_flex_img">
                  <img alt="" src={Img2}/>
              </div>
          </div>
          <div className="PublicFood_flex_box__element">
              <div className="PublicFood_flex_text">
                  <h4>{lang.Fitness_subtitle_4}</h4>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text9}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text10}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text11}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text12}
                  </p>
              </div>
              <div className="PublicFood_flex_img">
                  <img alt="" src={Img3}/>
              </div>
          </div>
          <div className="PublicFood_flex_box__element" id="PublicFood_box__element">
              <div className="PublicFood_flex_text">
                  <h4>{lang.Fitness_subtitle_5}</h4>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text13}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text14}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text15}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text16}
                  </p>
              </div>
              <div className="PublicFood_flex_img">
                  <img alt="" src={Img4}/>
              </div>
          </div>
          <div className="PublicFood_flex_box__element" >
              <div className="PublicFood_flex_text">
                  <h4>{lang.Fitness_subtitle_6}</h4>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text17}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text18}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text19}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text20}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text21}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text22}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text23}
                  </p>
                  <p>
                      <img alt="icon" src={icon} />
                      {lang.Fitness_text24}
                  </p>

              </div>
              <div className="PublicFood_flex_img">
                  <img alt="" src={Img5}/>
              </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="PublicFood_block">
            <div className="PublicFood_support_text">
            <h2>{lang.Fitness_title3}</h2>
                <div className="PublicFood_text">
                        <div className="Hotels_text_row" > 
                          <div className="Hotels_text_column" >
                            <p className="Fitness_paragraf">
                                {lang.Fitness_text25}
                            </p>
                            <p className="Fitness_paragraf">
                                {lang.Fitness_text26}
                            </p>
                          </div>
                          <div className="Hotels_text_column" >
                           <p className="Fitness_paragraf"> 
                                {lang.Fitness_text27}
                            </p>
                            <p className="Fitness_paragraf"> 
                                {lang.Fitness_text28}
                            </p>
                           </div>
                           <div className="Hotels_text_column" >
                            <p className="Fitness_paragraf">
                              {lang.Fitness_text29}
                            </p>
                            <p className="Fitness_paragraf">
                              {lang.Fitness_text30}
                            </p>
                           </div>
                           <div className="Hotels_text_column" > 
                            <p className="Fitness_paragraf"> 
                                {lang.Fitness_text31}
                            </p>
                            <p className="Fitness_paragraf"> 
                                {lang.Fitness_text32}
                            </p>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="PublicFood_iphone Hotels_desctopImg">
            <img id="iphone" alt="phone" src={desktop} />
            </div>
            <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
      </div>
     {/* slide logos of partners */}
     <SlideLine lang={lang}/>

     {/* Blue_block */}
     <div className="PublicFood_Blue_block">
           
           <p>{lang.blue_block_text5}</p>
           <div className="PublicFood_button_wrap">
               <button className="Blue_block_button" onClick={()=>{
      setIsTrue(true)
    }}>
               {lang.Calculate_the_cost}
               </button>
           </div>
     </div>
    </div>
    ) 
  }
  
  export default Fitness